import { logout } from "@/redux/slice/auth/authSlice";
import toast from "react-hot-toast";
// import { useHistory } from "react-router-dom";

export const globalMiddleware = (store) => (next) => (action) => {
  if (action.error && action.payload && action.payload.status === 401) {
    // Dispatch logout action
    store.dispatch(logout());

    // toast.error("Session expired. Please log in again.");
  } 
  // else if (action.error && action.payload && action.payload.status === 422) {
  //   toast.error("please fill Required field");
  // }
  if (action.type.endsWith("fulfilled")) {
    if (action?.payload?.message) {
      toast.success(action?.payload?.message);
    }
  } else if (action.type.endsWith("rejected")) {
    if (action?.payload?.data?.message) {
      toast.error(action?.payload?.data?.message);
    }
  }

  return next(action);
};