import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editor:null
};

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    getEditor: (state, action) => {
      state.editor = action.payload.editor;
    },
  },
});

export const { getEditor } = articleSlice.actions;
export default articleSlice.reducer;