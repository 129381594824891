import { login } from "@/redux/slice/auth/authSlice";
import toast from "react-hot-toast";

export const authMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.payload && action?.meta?.arg?.endpointName === "login") {
      if (action.error && action.payload && action.payload.status === 406) {
        // toast.error(action.payload.data.message);
      } else if (action.type.endsWith("/fulfilled")) {
        dispatch(
          login({
            token: action?.payload?.token,
            user:action?.payload?.user
          })
        );
        // toast.success("successfully Login");
      }
    }

    // if (action.payload && action?.meta?.arg?.endpointName === "register") {
    //   if (action.error && action.payload && action.payload.status === 400) {
    //     toast.error(action.payload.data.message);
    //   } else if (action.type.endsWith("/fulfilled")) {
    //     dispatch(login({ token: action.payload.token }));
    //     toast.success("successfully Register");
    //   }
    // }

    return next(action);
  };